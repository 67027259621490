import { UserContext, handleLogin, isLoggedIn } from '@parallelpublicworks/entitysync';
import { Link, navigate } from "gatsby"
import { InlineNotification } from 'gatsby-theme-carbon';
import { Button, Form, TextInput, Modal } from 'carbon-components-react';
import React, {useContext, useEffect, useState} from 'react';
//import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
//import recaptchaSubmit from '../util/recaptchaSubmit'
import { siteMetadata } from "../../gatsby-config";
const axios = require('axios');
 
function Login({location, setError}) {
  const userContext = useContext(UserContext)
  //const { executeRecaptcha } = useGoogleReCaptcha();
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [profEmail, setProfEmail] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    //e.persist()
    setIsLoading(true);
    try {
      //const valid = await recaptchaSubmit(executeRecaptcha)
      //if (!valid) throw "Failed recaptcha"
      const resp = await handleLogin(userContext, e)
      if (!resp) setError(<span>Login was unsuccessful</span>)
      setIsLoading(false)
    } catch (err) {
      setError(err)
      setIsLoading(false)
    }
  }

  const handleReset = async (e) => {
    e.preventDefault()
    try {
      const base = siteMetadata.drupalUrl;
      const jsonapiEndpoint = 'professor/reset';
      const url = `${base}/${jsonapiEndpoint}`;
      if (typeof window !== `undefined`) {
        const resp = await axios.post(url, {email: profEmail, reset: false})
        if (resp.status === 401 || resp.status === 403) {
          // error occurred
          // return false; 
        } else if (resp.status === 200) {
          // good response
        }        
      }
    } catch (e) {
      // an error occurred
    }
    setOpen(false)
  }

  useEffect(() => {
    if (typeof userContext[0] !== `undefined` && isLoggedIn(userContext)) {
      const redirectPath = location.state ? location.state.referrer : false
      navigate(redirectPath ? redirectPath : "/")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeof userContext[0] !== `undefined` && isLoggedIn(userContext)])

  useEffect(() => {
    if (location?.state?.username && location?.state?.username) {
      document.querySelector("button[type='submit']").click()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return location?.state?.username && location?.state?.username ? (
    <>
      <Modal 
        modalHeading="Professor Login Reset"
        primaryButtonText="Reset"
        secondaryButtonText="Cancel"
        open={open}
        onRequestClose={() => setOpen(false)}
        onRequestSubmit={handleReset} >
        <p style={{ marginBottom: '1rem' }}>If a professor account with the given email exists it will receive a new login link</p>
        <TextInput data-modal-primary-focus
                   id="reset-email"
                   labelText="Email"
                   onChange={(val) => setProfEmail(val.target.value)} />
      </Modal>
      <Form className="login-form" onSubmit={handleSubmit}>
        <TextInput
          light
          type="text"
          required
          labelText="Email"
          name="username"
          id="username"
          value={location.state.username}
        />
        <TextInput
          light
          type="password"
          required
          labelText="Password"
          name="password"
          id="password"
          value={location.state.password}
        />
        <Button type="submit" disabled={isLoading}>Login</Button>
        <Button onClick={() => setOpen(true)} kind="ghost" >Trouble logging in?</Button>
      </Form>
    </>
  ) : (
    <Form className="login-form" onSubmit={handleSubmit}>
      <TextInput
        light
        type="text"
        required
        labelText="Username"
        name="username"
        id="username"
      />
      <TextInput
        light
        type="password"
        required
        labelText="Password"
        name="password"
        id="password"
      />
      <Button type="submit" disabled={isLoading}>Login</Button>
      <div><Link to="/password-reset">Forgot password?</Link></div>
      <div><Link to="/register">Register here</Link></div>
    </Form>
  )
}


export default function LoginPage({pageContext, location}) {
  pageContext = {...pageContext, frontmatter: {title: "Login"}};
  const [error, setError] = useState(null)
  return (
      <>
        {error && <InlineNotification kind="error">{error}</InlineNotification>}
        <Login location={location} setError={setError} /> 
      </>
  ) 
}
